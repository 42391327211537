/* eslint-disable */
import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
// import QRCode from 'react-qr-code';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Snackbar from '@material-ui/core/Snackbar';
import Button from 'components/Button';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';
import { QRCodeSVG } from 'qrcode.react';
import { toCurrency } from 'helpers';

export default function QrCodeComponent({ qrCodeSerial, amount, mediaScreen }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <div
      className={`${classes.qrcode} tw-space-y-2 lg:tw-space-y-6 tw-py-6 lg:tw-py-10`}
    >
      <Typography
        variant='h6'
        className='tw-text-center !tw-text-base lg:!tw-text-xl'
      >
        Aponte a camera do celular para o QR Code ou copie o código no botão
        abaixo
      </Typography>
      <div className='tw-p-4 tw-bg-white tw-rounded-lg tw-border-4 tw-border-primary'>
        <QRCodeSVG
          size={mediaScreen === 'mobile' ? 150 : 256}
          value={qrCodeSerial}
        />
      </div>
      <p className='tw-font-semibold tw-text-xl lg:tw-text-3xl tw-text-primary'>
        Valor {toCurrency(amount)}
      </p>
      <CopyToClipboard text={qrCodeSerial} onCopy={() => setOpen(true)}>
        <Button
          variant='contained'
          color='primary'
          className='!tw-text-secondary-light'
        >
          Copiar QR Code
        </Button>
      </CopyToClipboard>
      <Snackbar
        title='Copiado com sucesso'
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity='success'>
          QR Code copiado com sucesso
        </Alert>
      </Snackbar>
    </div>
  );
}

QrCodeComponent.propTypes = {
  qrCodeSerial: PropTypes.string.isRequired,
  amount: PropTypes.number,
  mediaScreen: PropTypes.string,
};
