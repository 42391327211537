/*eslint-disable*/

export const colors = {
  white: '#FFF',
  orange: '#00ff67',
  blueDark: '#000000',
  neutrals: {
    0: '#00000',
    20: '#313131',
    90: '#D7DBEC',
    100: '#FFFFFF',
  },
};
