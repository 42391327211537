/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from 'components/Button';
import useStyles from './styles';
import icon from 'assets/wallet/icon-transfer.svg';

export default function WalletMessageComponent({
  title,
  message,
  buttonName,
  route,
}) {
  const classes = useStyles();

  return (
    <div className={`${classes.message} tw-py-6 lg:tw-py-10`}>
      <div className={classes.icon}>
        <img src={icon} />
      </div>
      <Typography variant='h6'>{title}</Typography>
      <Typography className={classes.instruction}>{message}</Typography>
      {buttonName ? (
        <Button
          variant='contained'
          color='primary'
          className='!tw-text-secondary-light'
          component={Link}
          to={route}
        >
          {buttonName}
        </Button>
      ) : null}
    </div>
  );
}

WalletMessageComponent.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.node,
  buttonName: PropTypes.string,
  route: PropTypes.node,
};
