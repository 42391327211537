/* eslint-disable */
import React, { Suspense } from 'react';
import routes from './routes';
import menu from './menu';
import SideMenu from 'components/SideMenu';
import RouteManager from 'components/RouteManager';
import useRouteScrollRestore from 'hooks/useRouteScrollRestore';
import 'swiper/swiper-bundle.min.css';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import whatsapp from '../assets/icons/whatsapp.png';
import { useHistory } from 'react-router-dom';

export default function Pages() {
  useRouteScrollRestore();
  let location = useLocation();
  return (
    <SideMenu data={menu}>
      {!location.pathname.toLowerCase().includes('/cassinogames') && (
        <div className='tw-z-50 tw-fixed tw-top-[80%] md:tw-top-[92%] tw-right-4 md:tw-right-8'>
          <a
            className='tw-w-14 tw-h-14 tw-flex tw-justify-center tw-items-center tw-rounded-full hover:tw-bg-primary/30 tw-duration-500'
            href='https://wa.me/5511995529147'
          >
            <img src={whatsapp} className='tw-h-12 tw-w-12' />
          </a>
        </div>
      )}
      <RouteManager routes={routes} />
    </SideMenu>
  );
}
