/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { Button } from 'components/Tailwind/button/button';
import { XMarkIcon } from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';
import { DepositForm } from './depositForm';
import { Dialog } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import logoPix from '../../../../assets/icons/pix.png';
import clsx from 'clsx';
// import { Countdown } from 'components/Tailwind/countdown/countdown';
import { useLocation } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

export const ModalDeposit = ({
  orientationMobile,
  mediaScreen,
  withTag,
  shape,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (location.pathname.includes('/testeDeposito') && !!currentUser)
      setIsOpen(true);
  }, [location]);

  useEffect(() => {
    const handleOpenModalDeposit = () => {
      setIsOpen(true);
    };

    document.addEventListener(
      'openModalDeposit',
      handleOpenModalDeposit,
      false
    );

    return () => {
      document.removeEventListener('openModalDeposit', handleOpenModalDeposit);
    };
  }, []);

  return (
    <>
      <Button
        className={clsx(
          '!tw-py-1 !tw-px-4 tw-text-xs tw-relative',
          orientationMobile
            ? 'md:!tw-py-1 md:!tw-px-1.5 md:text-xs'
            : 'md:!tw-py-1.5 md:!tw-px-5 md:tw-text-base'
        )}
        onClick={() => setIsOpen(true)}
        shape={shape}
      >
        {withTag && (
          <span className='tw-bg-white tw-text-black tw-text-[8px] md:tw-text-xs tw-rounded-full tw-px-1.5 tw-flex tw-justify-center tw-items-center tw-gap-0.5 tw-absolute -tw-top-2 tw-right-0 tw-h-3 md:tw-h-4'>
            <img src={logoPix} className='tw-w-2 tw-h-2 md:tw-w-3 md:tw-h-3' />
            Pix
          </span>
        )}
        <span>{t('money.deposit')}</span>
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className='tw-relative tw-z-50'
      >
        <div
          className='tw-fixed tw-inset-0 tw-bg-gray-700/70'
          aria-hidden='true'
        />
        <div className='tw-fixed tw-inset-0 tw-flex tw-w-screen tw-items-center tw-justify-center'>
          <Dialog.Panel className='tw-bg-secondary-light tw-relative tw-rounded-lg tw-max-w-[90vw] lg:tw-max-w-xl tw-max-h-[90vh] lg:tw-max-h-[80vh]'>
            {/* <div className='tw-relative tw-z-10'>
              <img
                src='https://palpitenarede-assets.s3.us-east-1.amazonaws.com/banners/banner-modal-728x238-post.jpg'
                className='!tw-rounded-t-lg !tw-rounded-b-none !tw-h-28 md:!tw-h-48 tw-w-full tw-object-cover'
              />
            </div> */}
            <button
              onClick={() => setIsOpen(false)}
              className='tw-absolute -tw-top-3 -tw-right-3 tw-z-50 tw-h-10 tw-w-10 focus:tw-outline-none'
            >
              <XMarkIcon className='tw-bg-secondary-light tw-rounded-lg tw-text-gray-200 hover:tw-text-gray-400' />
            </button>
            <Dialog.Description
              as='div'
              className='tw-px-4 md:tw-px-10 tw-py-3 md:tw-py-5 tw-z-0 tw-max-h-[calc(90vh-112px)] md:tw-max-h-[calc(80vh-204px)] tw-overflow-y-auto scrollBar'
            >
              {/* <Countdown timeInSeconds={10} /> */}
              <DepositForm mediaScreen={mediaScreen} />
            </Dialog.Description>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

ModalDeposit.propTypes = {
  orientationMobile: PropTypes.bool,
  mediaScreen: PropTypes.string,
  user: PropTypes.object,
  withTag: PropTypes.bool,
  shape: PropTypes.string,
};
