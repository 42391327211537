/*eslint-disable */
import React, { useEffect, useState, cloneElement } from 'react';
import { NavLink } from 'react-router-dom';
import { WalletIcon } from '@heroicons/react/24/solid';
import esportesIcon from '../../assets/icons/esportes.png';
import bingoIcon from '../../assets/icons/icon globo mobile.png';
import VideoBingoIcon from '../../assets/icons/icon video bingo.png';
import cassinoIcon from '../../assets/icons/icon cassino.png';
import loteriaPopularIcon from '../../assets/icons/newLoteriaPopular.png';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import cassinoAoVivoIcon from '../../assets/icons/icon dados.png';
import carteira from '../../assets/icons/icon carteira.png';

export const MobileBottomMenu = ({ currentUser }) => {
  const history = useHistory();
  const pathname = history.location.pathname;
  return (
    <div
      className={`tw-bg-secondary-light tw-h-20 tw-w-full tw-fixed tw-bottom-0 tw-left-0 tw-z-50 tw-grid tw-grid-cols-5 ${
        pathname.includes('/vibraGames') ||
        pathname.includes('/caletaGames') ||
        pathname.includes('/every-matrix') ||
        pathname.includes('/cassinoGames')
          ? 'tw-hidden'
          : 'tw-block'
      }`}
    >
      <NavlinkComponent
        url={!currentUser ? '/entrar' : '/carteira'}
        image={carteira}
        label='Carteira'
      />
      <NavlinkComponent url='/cassino' image={cassinoIcon} label='Cassino' />
      {/* <div className='tw-bg-secondary tw-rounded-full tw-h-22 tw-w-22 tw-relative tw-flex tw-items-center tw-justify-center -tw-top-2'> */}
      <NavlinkComponent
        url={'/cassinoGames/Banana Bingo/bananaGames/2/6'}
        image={bingoIcon}
        label='Bingo'
        description='ao vivo'
        live
        // center
        // width={'tw-w-9'}
        // height={'tw-w-9'}
      />
      {/* </div> */}
      <NavlinkComponent
        url={'/cassino/Video Bingo'}
        image={VideoBingoIcon}
        label='Video bingo'
        // description='bingo'
      />
      <NavlinkComponent
        url='/cassino/Cassino ao vivo'
        image={cassinoAoVivoIcon}
        label='Cassino'
        description='ao vivo'
      />
      {/* <NavlinkComponent url='/esportes' image={esportesIcon} label='Esportes' /> */}
      {/* <NavlinkComponent
        url='/loteria-popular'
        image={loteriaPopularIcon}
        label='Loteria'
        description='popular'
      /> */}
    </div>
  );
};

MobileBottomMenu.propTypes = {
  currentUser: PropTypes.object,
};

const NavlinkComponent = ({
  image,
  icon,
  url,
  label,
  description,
  live,
  height = 'tw-h-7',
  width = 'tw-w-7',
  center,
}) => {
  const [intervalString, setIntervalString] = useState(
    !description ? false : true
  );

  const ImageElement = icon
    ? cloneElement(icon, {
        className: 'tw-h-7 tw-w-7 tw-text-primary',
      })
    : null;

  useEffect(() => {
    if (!description) return;
    const interval = setInterval(() => {
      setIntervalString(prev => !prev);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <NavLink
      to={url}
      className='tw-flex tw-flex-col tw-gap-1 tw-justify-center tw-items-center tw-relative'
    >
      {live && (
        <>
          <span className='tw-absolute tw-top-1.5 tw-right-1.5 tw-h-1.5 tw-w-1.5 tw-bg-danger-500 tw-animate-ping tw-rounded-full' />
          <span className='tw-absolute tw-top-1.5 tw-right-1.5 tw-h-1.5 tw-w-1.5 tw-bg-danger-500/50 tw-rounded-full' />
        </>
      )}

      {ImageElement}
      {image && (
        <img src={image} className={`${height} ${width} tw-text-secondary`} />
      )}
      <p className='tw-font-black tw-text-xs'>
        {!intervalString ? label : description}
      </p>
    </NavLink>
  );
};

NavlinkComponent.propTypes = {
  image: PropTypes.string,
  icon: PropTypes.element,
  url: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  live: PropTypes.bool,
  hieght: PropTypes.string,
  width: PropTypes.string,
  center: PropTypes.bool,
};
