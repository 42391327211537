/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => {
  return {
    menuContainer: {
      '& ul': {
        backgroundColor: '#0d0d0d',
      },
    },
    flagArea: {
      // position: 'relative',
      // right: '-12px',
      width: 'fit-content',
    },
    typography: {
      fontSize: '12px',
      marginLeft: '8px',
      textTransform: 'capitalize',
    },
  };
});
