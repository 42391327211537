/*eslint-disable*/

import { createTheme as createMuiTheme } from '@material-ui/core/styles';

import { Button } from './components/button';
import { Toolbar } from './components/toolbar';
import { colors } from './colors';
import { Accordion } from './components/accordion';
import { Container } from './components/container';
import { Input } from './components/input';
import { Chip } from './components/chip';

const ball = ['#00ff67'];

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: colors.orange,
      contrastText: colors.neutrals[100],
    },
    custom: {
      card: '#042E53',
      ball,
      getBallColor: (value, max) =>
        ball[Math.ceil(parseInt(value) / (max / ball.length)) - 1],
      acumulado: {
        light: '#CDCECD',
        main: '#E8C900',
        dark: '#C2A900',
        card: '#5C5000',
        number: '#DBBE00',
      },
      bingo: {
        light: '#CDCECD',
        main: '#54B12D',
        dark: '#12A60D',
        card: '#023600',
        number: '#12A60D',
      },
      linha: {
        light: '#DFE0DF',
        main: '#2676D2',
        dark: '#055ABC',
        card: '#042E53',
        number: '#0D7AD9',
      },
      quadra: {
        light: '#B9B9B9',
        main: '#F57C28',
        dark: '#00ff67',
        card: '#882503',
        number: '#FE5418',
      },
    },
    secondary: {
      main: colors.blueDark,
      contrastText: colors.neutrals[100],
    },
    text: {
      primary: colors.neutrals[100],
      secondary: colors.neutrals[20],
      disabled: '#CCCCCC',
    },
    background: {
      default: colors.blueDark,
      paper: '#0F111D',
      gray: colors.neutrals[20],
      section: '#F1ECEC',
      footer: '#0d0d0d',
    },
    grey: {
      400: colors.neutrals[90],
    },
    overlay: '#00000073',
  },
  typography: {
    h1: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: '2.25rem',
    },
    h2: {
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: '1.625rem',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5rem',
    },
    badge: {
      fontSize: '0.60rem',
      fontWeight: 700,
    },
    box: {
      fontSize: '1.125rem',
      fontWeight: 600,
    },
    input: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '1.375rem',
    },
    chip: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
    },
    fontWeightSemiBold: 600,
  },
  spacing: 2,
  shape: {
    borderRadius: 6,
  },
});

theme.overrides = {
  MuiButton: Button,
  MuiToolbar: Toolbar,
  MuiAccordion: Accordion,
  MuiContainer: Container(theme),
  MuiTextField: Input(theme),
  MuiChip: Chip(theme),
};

export default theme;
