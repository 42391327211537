/*eslint-disable */
import React from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const BUTTON_VARIANT_MAP = {
  primary: {
    variant: {
      solid:
        'tw-bg-primary tw-text-secondary-light hover:tw-bg-primary/70 focus:tw-ring-primary-light',
      outline:
        'tw-border tw-border-solid tw-border-primary tw-text-primary tw-bg-transparent hover:tw-bg-primary/70 hover:tw-text-white',
      dashed:
        'tw-border tw-border-dashed tw-border-primary tw-text-primary tw-bg-transparent hover:tw-bg-primary/70 hover:tw-text-white hover:tw-border-none',
      link: 'tw-border-0 tw-bg-transparent tw-text-primary hover:tw-underline hover:tw-underline-offset-4',
    },
  },
  danger: {
    variant: {
      solid: 'tw-bg-danger tw-text-white hover:tw-bg-danger-dark',
      outline:
        'tw-border tw-border-solid tw-border-danger tw-text-danger tw-bg-transparent hover:tw-bg-danger-dark hover:tw-text-white',
      dashed:
        'tw-border tw-border-dashed tw-border-danger tw-text-danger tw-bg-transparent hover:tw-bg-danger-dark hover:tw-text-white',
      link: 'tw-border-0 tw-bg-transparent tw-text-danger hover:tw-underline hover:tw-underline-offset-4',
    },
  },
  warning: {
    variant: {
      solid: 'tw-bg-warning tw-text-white hover:tw-bg-warning-dark',
      outline:
        'tw-border tw-border-solid tw-border-warning tw-text-warning tw-bg-transparent hover:tw-bg-warning-dark hover:tw-text-white',
      dashed:
        'tw-border tw-border-dashed tw-border-warning tw-text-warning tw-bg-transparent hover:tw-bg-warning-dark hover:tw-text-white',
      link: 'tw-border-0 tw-bg-transparent tw-text-warning hover:tw-underline hover:tw-underline-offset-4',
    },
  },
  success: {
    variant: {
      solid: 'tw-bg-success tw-text-white hover:tw-bg-success-dark',
      outline:
        'tw-border tw-border-solid tw-border-success tw-text-success tw-bg-transparent hover:tw-bg-success-dark hover:tw-text-white',
      dashed:
        'tw-border tw-border-dashed tw-border-success tw-text-success tw-bg-transparent hover:tw-bg-success-dark hover:tw-text-white',
      link: 'tw-border-0 tw-bg-transparent tw-text-success hover:tw-underline hover:tw-underline-offset-4',
    },
  },
  secondary: {
    variant: {
      solid:
        'tw-bg-secondary tw-text-white hover:tw-bg-secondary/70 focus:tw-ring-secondary-light',
      outline:
        'tw-border tw-border-solid tw-border-secondary tw-text-secondary tw-bg-transparent hover:tw-bg-secondary/70 hover:tw-text-white',
      dashed:
        'tw-border tw-border-dashed tw-border-secondary tw-text-secondary tw-bg-transparent hover:tw-bg-secondary/70 hover:tw-text-white hover:tw-border-none',
      link: 'tw-border-0 tw-bg-transparent tw-text-secondary hover:tw-underline hover:tw-underline-offset-4',
    },
  },
};

const BUTTON_SIZE = {
  xs: 'tw-px-2.5 tw-py-1.5 tw-text-xs tw-font-medium',
  sm: 'tw-px-3 tw-py-2 tw-text-sm tw-font-medium',
  md: 'tw-px-4 tw-py-2 tw-text-sm tw-font-medium',
  lg: 'tw-px-4 tw-py-2 tw-text-base tw-font-medium',
  xl: 'tw-px-6 tw-py-3 tw-text-base tw-font-medium',
};

const ICON_SIZE = {
  xs: 'tw-w-3 tw-h-3',
  sm: 'tw-w-[0.875rem] tw-h-[0.875rem]',
  md: 'tw-w-[0.875rem] tw-h-[0.875rem]',
  lg: 'tw-w-4 tw-h-4',
  xl: 'tw-w-4 tw-h-4',
};

export const Button = ({
  onClick,
  disabled = false,
  loading = false,
  children,
  icon,
  size = 'sm',
  htmlType = 'button',
  color = 'primary',
  variant = 'solid',
  full = false,
  shape = 'square',
  className,
}) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        'tw-flex tw-justify-center tw-items-center tw-gap-1',
        shape === 'rounded' ? 'tw-rounded-full' : 'tw-rounded-md',
        disabled
          ? '!tw-cursor-not-allowed !tw-bg-gray-300/50 hover:!tw-bg-gray-300/30 !tw-text-white'
          : '',
        BUTTON_SIZE[size],
        BUTTON_VARIANT_MAP[color].variant[variant],
        full ? 'tw-w-full' : null,
        className
      )}
      disabled={disabled}
      type={htmlType}
    >
      {loading && (
        <div className='tw-animate-spin tw-h-5 tw-w-5'>
          <ArrowPathIcon />
        </div>
      )}
      {icon && (
        <span
          className={clsx(
            ICON_SIZE[size],
            'tw-text-inherit tw-flex tw-items-center tw-justify-center'
          )}
        >
          {icon}
        </span>
      )}
      {children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node,
  icon: PropTypes.element,
  size: PropTypes.string,
  htmlType: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  full: PropTypes.bool,
  className: PropTypes.string,
  shape: PropTypes.string,
};
