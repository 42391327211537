/*eslint-disable */
import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import { useDeviceInfo } from 'hooks/useDeviceInfo';
import { Dialog } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Button as TwButton } from 'components/Tailwind/button/button';
import { FormSignup } from './formSignup';
import { useLocation } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
// import { Banners } from 'components/Tailwind/banner/banner';
import PropTypes from 'prop-types';

export const ModalSignup = ({ isMobile }) => {
  const [bonus, setBonus] = useState('');
  const { media, device } = useDeviceInfo();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [cancelSignup, setCancelSignup] = useState(false);
  const location = useLocation();
  const { currentUser } = useAuth();

  useEffect(() => {
    const url = new URL(window?.location?.href);
    const searchParams = url.searchParams;
    const campaignName = searchParams.get('campaign_name');

    setBonus(campaignName);
    // eslint-disable-next-line
  }, []);

  if (media === 'mobile' && device === 'iPhone') {
    window.scrollTo(0, 0);
  }

  const handleCloseDialog = () => {
    setIsOpen(false);
    setCancelSignup(false);
  };

  useEffect(() => {
    const handleOpenModal = () => {
      setIsOpen(true);
    };

    document.addEventListener('openModalSignup', handleOpenModal, false);

    return () => {
      document.removeEventListener('openModalSignup', handleOpenModal);
    };
  }, []);

  useEffect(() => {
    const queryString = location.search;
    const searchParams = new URLSearchParams(queryString);
    const afiliateCode = searchParams.get('aff');
    if (searchParams.has('aff') && !currentUser) {
      localStorage.setItem('afiliateCode', afiliateCode);
      const openModalEvent = new window.Event('openModalSignup');
      document.dispatchEvent(openModalEvent);
    }
  }, [location, currentUser]);

  return (
    <>
      <Button
        id='app-bar-register'
        variant='contained'
        color='primary'
        size='small'
        className='!tw-text-secondary-light'
        onClick={() => setIsOpen(true)}
      >
        <span>{t('appBar.signup')}</span>
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => setCancelSignup(true)}
        className='tw-relative tw-z-50'
      >
        <div
          className='tw-fixed tw-inset-0 tw-bg-gray-700/70'
          aria-hidden='true'
        />
        <div className='tw-fixed tw-inset-0 tw-flex tw-w-screen tw-items-center tw-justify-center'>
          <Dialog.Panel className='tw-bg-secondary-light tw-relative tw-rounded-lg tw-max-w-[90vw] lg:tw-max-w-xl tw-max-h-[90vh] lg:tw-max-h-[80vh]'>
            {/* <div className='tw-relative tw-z-10'>
              <img
                src='https://palpitenarede-assets.s3.us-east-1.amazonaws.com/banners/banner-modal-728x238-post.jpg'
                className='!tw-rounded-t-lg !tw-rounded-b-none !tw-h-28 md:!tw-h-48 tw-w-full tw-object-cover'
              />
            </div> */}
            {!cancelSignup && (
              <button
                className='tw-absolute -tw-top-3 -tw-right-3 tw-z-50 tw-h-10 tw-w-10 focus:tw-outline-none'
                onClick={() => setCancelSignup(true)}
              >
                <XMarkIcon className='tw-bg-secondary-light tw-rounded-lg tw-text-gray-200 hover:tw-text-gray-400' />
              </button>
            )}
            <Dialog.Description
              as='div'
              className='tw-px-4 md:tw-px-10 tw-pb-2 md:tw-pb-4 tw-relative -tw-top-2 tw-z-0 tw-max-h-[calc(90vh-112px)] md:tw-max-h-[calc(80vh-204px)] tw-overflow-y-auto scrollBar'
            >
              {cancelSignup ? (
                <div className='tw-flex tw-gap-4 tw-flex-col tw-justify-center tw-py-4 md:tw-pt-8'>
                  <p className='tw-text-lg md:tw-text-2xl tw-font-bold tw-text-center'>
                    Tem certeza que deseja cancelar seu registro?
                  </p>
                  <div className='tw-mt-2 tw-flex tw-flex-col tw-gap-2 md:tw-gap-4'>
                    <TwButton onClick={() => setCancelSignup(false)} size='lg'>
                      Continuar
                    </TwButton>
                    <TwButton
                      color='secondary'
                      onClick={handleCloseDialog}
                      size='lg'
                    >
                      Cancelar registro
                    </TwButton>
                  </div>
                </div>
              ) : (
                <FormSignup closeModal={setIsOpen} />
              )}
            </Dialog.Description>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

ModalSignup.propTypes = {
  isMobile: PropTypes.bool,
};
