/* eslint-disable */
import React, { memo, useState, useEffect, Suspense } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import BalanceIcon from '@material-ui/icons/AccountBalanceWallet';
import useAuth from 'hooks/useAuth';
import useStyles from './styles';
import { Link } from 'react-router-dom';
import { signInRoute } from 'pages/routes';
import { home } from 'pages/Wallet/routes';
import { toMoney } from 'helpers/format';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LanguageMenu } from 'components/AppBar';
import { useTranslation } from 'react-i18next';

function SideMenuHeaderComponent({ onClick }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { currentUser } = useAuth();
  const [walletAmount, setWalletAmount] = useState(currentUser?.Wallet?.amount);

  useEffect(() => {
    if (currentUser?.roleName == 'SELLER') {
      setWalletAmount(currentUser?.Wallet?.amountToSell);
    } else {
      setWalletAmount(
        currentUser?.Wallet?.amountBonus +
          currentUser?.Wallet?.amountAward +
          currentUser?.Wallet?.amountDeposit +
          currentUser?.Wallet?.amountBonusSports
      );
    }
  }, [currentUser?.Wallet, currentUser?.roleName]);

  return (
    <div className={classes.header}>
      <Avatar className={classes.avatar} src={currentUser?.avatar} />
      {currentUser ? (
        <>
          <Typography className={`${classes.userName} tw-text-secondary-light`}>
            {t('hello')}, {currentUser.name}
          </Typography>

          <Button
            variant='contained'
            className={classes.userBalance}
            component={Link}
            to={'/perfil-usuario'}
            onClick={onClick}
          >
            {t('editProfile')}
          </Button>

          <Button
            variant='contained'
            startIcon={<BalanceIcon />}
            className={classes.userBalance}
            component={Link}
            to={home}
            onClick={onClick}
          >
            <span>{toMoney(walletAmount)}</span>
          </Button>
          {/* <div className='tw-w-full tw-flex tw-justify-end'>
            <Suspense fallback={<CircularProgress size={20} />}>
              <LanguageMenu />
            </Suspense>
          </div> */}
        </>
      ) : (
        <Button
          size='small'
          color='inherit'
          variant='outlined'
          component={Link}
          to={signInRoute}
          onClick={onClick}
        >
          Entrar
        </Button>
      )}
    </div>
  );
}

SideMenuHeaderComponent.propTypes = {
  onClick: PropTypes.func,
};

export default memo(SideMenuHeaderComponent);
